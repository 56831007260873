// External libraries
import { useMutation, UseMutationResult } from '@tanstack/react-query'

// Services and API calls
import { deleteDocumentFromDB, listAllDocumentsFromDB } from '@/api'

// Global utilities and constants
import { queryClient } from '@/App'
import { toast } from 'sonner'

// Hooks and Jotai Atoms
import { userAtom } from '@/store/store'
import { useAtomValue } from 'jotai'
import { documentKeys } from './documentsQueryKeys'
import type { CourseDocuments } from '@/types'

/**
 * Custom hook for deleting course documents with comprehensive cache invalidation strategy.
 *
 * Cache Management Strategy:
 * - Global documents list: documentKeys.all
 * - User-specific documents: documentKeys.byUser(userId)
 * - Course-specific documents: documentKeys.byCourse(userId, courseName)
 * - Document-specific data: documentKeys.documentData(courseName, fileName)
 *
 * Features:
 * - Multi-level cache invalidation and cleanup
 * - Optimistic updates for immediate UI feedback
 * - Automatic cache rollback on failure
 * - Comprehensive query cancellation before mutations
 * - Document data query cleanup post-deletion
 * - Delayed invalidation to sync with backend operations
 *
 * @example
 * ```tsx
 * const deleteMutation = useDeleteCourseDocumentMutation();
 *
 * // Single document deletion
 * deleteMutation.mutate(['courseName/fileName.pdf']);
 *
 * // Bulk document deletion
 * deleteMutation.mutate([
 *    'course1/file1.pdf',
 *    'course2/file2.pdf'
 * ]);
 * ```
 *
 * @returns {UseMutationResult<boolean, Error, string[], unknown>} Mutation object containing:
 * - mutate: Function to trigger document deletion
 * - isLoading: Boolean indicating deletion in progress
 * - isSuccess: Boolean indicating successful deletion
 * - isError: Boolean indicating deletion failure
 * - error: Error details if deletion failed
 */
const useDeleteCourseDocumentMutation = (): UseMutationResult<boolean, Error, string[], unknown> => {
   const { userId } = useAtomValue(userAtom)

   return useMutation({
      mutationFn: (filePaths: string[]) => deleteDocumentFromDB(filePaths),
      onMutate: async (filesToDelete) => {
         // Cancel all related queries first
         await Promise.all([
            queryClient.cancelQueries({ queryKey: documentKeys.all }),
            ...filesToDelete.map((filepath) => {
               const [courseName, fileName] = filepath.split('/')
               return queryClient.cancelQueries({
                  queryKey: documentKeys.documentData(courseName, fileName),
               })
            }),
         ])

         // Snapshot current state
         const previousData = queryClient.getQueryData(documentKeys.byUser(userId))

         // Perform optimistic update
         queryClient.setQueryData(documentKeys.byUser(userId), (old: CourseDocuments) => {
            const updated = { ...old }
            filesToDelete.forEach((filepath) => {
               const [courseName, fileName] = filepath.split('/')
               if (updated[courseName]) {
                  updated[courseName] = updated[courseName].filter((doc) => doc.fileName !== fileName)
               }
            })
            return updated
         })

         return { previousData }
      },
      onSuccess: async (_, filesToDelete) => {
         // Add delay to allow backend operations to complete
         await new Promise((resolve) => setTimeout(resolve, 500))

         // Fetch fresh data with cache invalidation flag
         const freshData = await listAllDocumentsFromDB(userId, new AbortController().signal, true)

         // Update all document queries with fresh data
         queryClient.setQueriesData({ queryKey: documentKeys.all }, freshData)
         // Remove individual document data queries that were deleted
         filesToDelete.forEach((filepath) => {
            const [courseName, fileName] = filepath.split('/')
            queryClient.removeQueries({
               queryKey: documentKeys.documentData(courseName, fileName),
            })
         })

         toast.success('Document/s deleted successfully!')
      },
      onError: (error, variables, context) => {
         // Rollback on error
         if (context?.previousData) {
            queryClient.setQueryData(documentKeys.byUser(userId), context.previousData)
         }

         toast.error("Couldn't Delete Document/s", {
            description:
               error instanceof Error
                  ? `${error.message}. Please try again or contact support.`
                  : 'Please try again or contact support.',
         })
      },
   })
}

export default useDeleteCourseDocumentMutation
