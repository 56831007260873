import { Document, CourseDocuments, DocumentData } from '@/types'
import { refreshAuthTokenAndUpdateUser } from '@/auth/utils'

const API_URL = import.meta.env.VITE_API_URL

/**
 * Adds a document to the database.
 *
 * This function sends a POST request to the server to add a new document. The document details
 * are passed as query parameters, and the document's data is sent in the request body. The user's
 * ID token is required for authorization.
 *
 * @param {Document} document - The document object containing the course name, file name, creator's username, and the document data.
 * @returns {Promise<boolean>} A promise that resolves to true if the document is successfully added, otherwise throws an error.
 * @throws {Error} Throws an error if the request fails or the server responds with an error.
 */
export const addDocumentToDB = async (document: Document): Promise<boolean> => {
   const query = `course_name=${encodeURIComponent(document.courseName)}&file_name=${encodeURIComponent(document.fileName)}&created_by=${encodeURIComponent(document.createdBy)}`
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/documents/add?${query}`, {
         method: 'POST',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
         body: document.documentData,
      })

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }
      return true
   } catch (error) {
      if (error instanceof Error) {
         throw new Error(error.message)
      }
      throw error
   }
}

/**
 * Lists all documents across all courses for a user with caching support.
 *
 * @param {string} userId - The ID of the user whose documents are to be listed
 * @param {AbortSignal} signal - An AbortSignal to cancel the request if needed
 * @param {boolean} invalidateCache - Optional flag to force cache refresh on the server
 * @returns {Promise<CourseDocuments>} A promise that resolves to an object containing all documents
 */
export const listAllDocumentsFromDB = async (
   userId: string,
   signal: AbortSignal,
   invalidateCache: boolean = false,
): Promise<CourseDocuments> => {
   const query = `?user_id=${encodeURIComponent(userId)}${invalidateCache ? '&invalidate_cache=true' : ''}`

   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/documents/list-all${query}`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
         signal,
      })

      if (response.status === 204) {
         return {}
      }

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }

      const body = await response.json()
      return body
   } catch (error) {
      if (signal.aborted) {
         return {}
      }
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred when listing all documents from db')
   }
}

/**
 * Deletes a document from the database.
 *
 * This function sends a DELETE request to the server to remove a specific document identified by its file name
 * and associated course name. The user's ID token is used for authorization in the request headers.
 *
 * @param {string} fileName - The name of the file/document to be deleted.
 * @param {string} courseName - The name of the course associated with the document.
 * @returns {Promise<boolean>} A promise that resolves to true if the document is successfully deleted, otherwise throws an error.
 * @throws {Error} Throws an error if the request fails, the server responds with an error, or if deleting the document fails.
 */
export const deleteDocumentFromDB = async (filesToDelete: string[]): Promise<boolean> => {
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/documents/delete`, {
         method: 'DELETE',
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
         },
         body: JSON.stringify(filesToDelete),
      })

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }

      return true
   } catch (error) {
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred when deleting the document from db')
   }
}

/**
 * Fetches the text data of a document from the database.
 *
 * This asynchronous function sends a GET request to the server to retrieve the text data of a document
 * specified by its course name and file name. The request requires authorization, provided via an ID token in the
 * request headers. An AbortSignal is also passed to allow the request to be cancelled if necessary.
 *
 * The function converts the received Blob into text before returning it.
 *
 * @param {string} courseName - The name of the course to which the document belongs.
 * @param {string} fileName - The name of the file/document to retrieve.
 * @param {number} pageIndex - The index of the page to fetch.
 * @param {AbortSignal} signal - An AbortSignal to optionally cancel the request.
 * @returns {Promise<string>} A promise that resolves to a string containing the document's text data.
 * @throws {Error} Throws an error if the request fails, the server responds with an error, or if the document data cannot be retrieved.
 */
export const getDocumentDataFromDB = async (
   courseName: string,
   fileName: string,
   pageIndex: number,
   signal: AbortSignal,
): Promise<DocumentData | undefined> => {
   const query = `?course_name=${encodeURIComponent(courseName)}&file_name=${encodeURIComponent(fileName)}&page_index=${encodeURIComponent(pageIndex)}`
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/documents/data${query}`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
         signal,
      })

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }

      const body = await response.json()
      return body
   } catch (error) {
      if (signal.aborted) {
         return
      }
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred when fetching the document data from db')
   }
}

/**
 * Processes files in the search storage.
 *
 * This function sends a POST request to the server to process files. The request body should contain
 * a list of document paths to be processed. The user's ID token is required for authorization.
 *
 * @param {string[]} documents - An array of document paths to be processed.
 * @returns {Promise<{ message: string }>} A promise that resolves to an object containing a success message.
 * @throws {Error} Throws an error if the request fails or the server responds with an error.
 */
export const processFiles = async (documents: string[]): Promise<{ message: string }> => {
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/documents/process`, {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
         },
         body: JSON.stringify(documents),
      })

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }

      const body = await response.json()
      return body
   } catch (error) {
      console.log('xxx', error)
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred when processing the files')
   }
}
