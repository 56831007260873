import { Course } from '@/types'

/**
 * This function takes an array of courses and returns an array of objects,
 * where each object contains a course name and an array of its sections.
 * Each course name is derived from the first 6 characters of the course's name,
 * and each section is derived from the remaining 3-4 characters.
 *
 * @param {Course[]} courseList - An array of Course objects.
 * @return {Object[]} An array of objects, each with a 'courseName' and 'sections' property.
 */
export function getCoursesNamesAndSections(courseList: Course[]) {
   const courseMap = new Map<string, string[]>()

   courseList.forEach((course) => {
      const courseName = course.courseName.substring(0, 6)
      const section = course.courseName.substring(6)

      const existingSections = courseMap.get(courseName)

      if (existingSections) {
         existingSections.push(section)
      } else {
         courseMap.set(courseName, [section])
      }
   })

   const result = Array.from(courseMap, ([courseName, sections]) => ({ courseName, sections }))

   return result
}

/**
 * This function takes an array of courses and returns an array of course names.
 * It maps over the courseList array and for each Course object, it extracts the 'name' property.
 *
 * @param {Course[]} courseList - An array of Course objects.
 * @return {string[]} An array of course names.
 */
export function getCoursesNames(courseList: Course[]) {
   return courseList.map((course) => course.courseName)
}
