import { Table } from '@tanstack/react-table'
import { Input } from '@/components/ui/input'
import { DataTableViewOptions } from '@/components/Table/data-table-view-options'
import NewDocumentDialog from '@/components/ManageDocuments/NewDocumentDialog'
import { DataTableFacetedFilter } from '@/components/Table/data-table-faceted-filter'
import { Filter } from '@/components/Table/filters'
import { X, Trash2, FolderSync } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import DeleteAlertDialog from '@/components/DeleteAlertDialog'
import useDeleteCourseDocumentMutation from '@/hooks/documents/useDeleteCourseDocumentMutation'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/store'
import { UserRoles } from '@/types'
import useProcessFiles, { DocumentInput } from '@/hooks/documents/useProcessFiles'

interface DataTableToolbarProps<TData> {
   readonly table: Table<TData>
   readonly className?: string
}

export function DataTableToolbar<TData>({ table, className }: DataTableToolbarProps<TData>) {
   const isFiltered = table.getState().columnFilters.length > 0
   const { userRole } = useAtomValue(userAtom)
   const { mutate } = useDeleteCourseDocumentMutation()
   const { mutate: processFilesMutate, isPending } = useProcessFiles()

   return (
      <div className={cn('flex w-full items-center justify-between space-x-2 overflow-auto p-1', className)}>
         <div className="flex flex-1 items-center space-x-2">
            <Input
               placeholder="Find Files..."
               value={(table.getColumn('fileName')?.getFilterValue() as string) ?? ''}
               onChange={(event) => table.getColumn('fileName')?.setFilterValue(event.target.value)}
               className="h-8 w-40 lg:w-64"
            />
            {table.getColumn('courseName') && (
               <DataTableFacetedFilter
                  column={table.getColumn('courseName')}
                  title="Course Name"
                  options={Filter({ column: table.getColumn('courseName') })}
               />
            )}
            {table.getColumn('createdBy') && (
               <DataTableFacetedFilter
                  column={table.getColumn('createdBy')}
                  title="Created By"
                  options={Filter({ column: table.getColumn('createdBy') })}
               />
            )}
            {isFiltered && (
               <Button aria-label="Reset filters" variant="ghost" size="sm" onClick={() => table.resetColumnFilters()}>
                  <X className="size-4" aria-hidden="true" />
                  Reset
               </Button>
            )}
         </div>
         <div className="flex items-center gap-2">
            {table.getFilteredSelectedRowModel().rows.length > 1 && (
               <>
                  {userRole === UserRoles.Dev && (
                     <Button
                        disabled={isPending}
                        size="sm"
                        onClick={() => {
                           const filesToProcess: DocumentInput[] = table
                              .getFilteredSelectedRowModel()
                              .rows.map((row) => ({
                                 courseName: row.renderValue('courseName'),
                                 fileName: row.renderValue('fileName'),
                                 createdBy: row.renderValue('createdBy'),
                              }))
                           processFilesMutate(filesToProcess)
                        }}
                     >
                        <FolderSync className="size-6 pr-2" />
                        {isPending ? 'Reprocessing...' : 'Reprocess Batch'}
                     </Button>
                  )}
                  <DeleteAlertDialog
                     handleOnClick={() => {
                        const filesToDelete = table
                           .getFilteredSelectedRowModel()
                           .rows.map((row) => `${row.renderValue('courseName')}/${row.renderValue('fileName')}`)

                        mutate(filesToDelete)
                     }}
                     icon={
                        <Button size="sm">
                           <Trash2 className="size-6 pr-2" />
                           Delete Selected
                        </Button>
                     }
                     description="You are going to delete all of the selected files"
                  />
               </>
            )}
            <NewDocumentDialog />
            <DataTableViewOptions table={table} ignoreColumn={1} />
         </div>
      </div>
   )
}
