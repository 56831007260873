import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, buttonVariants } from '@/components/ui/button'
import {
   Command,
   CommandDialog,
   CommandEmpty,
   CommandGroup,
   CommandInput,
   CommandItem,
   CommandList,
   CommandSeparator,
   CommandShortcut,
} from '@/components/ui/command'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { useConversationsSearchParams } from '@/hooks/monitor/useConversationsSearchParams'
import { cn } from '@/lib/utils'
import { Search, PlusCircle, CheckIcon, X, LoaderCircle } from 'lucide-react'
import { useState, useEffect } from 'react'
import useConversationCoursesUsers from '@/hooks/monitor/useConversationCoursesUsers'
import { toast } from 'sonner'

export const formSchema = z.object({
   username: z.string().email(),
   courseName: z.string().regex(/^[A-Za-z]{3}\d{3}[A-Za-z]{3,4}$/),
})

export default function ConversationSearchForm() {
   const location = useLocation()
   const navigate = useNavigate()
   const { username: usernameParam, courseName: courseNameParam, setSearchParams } = useConversationsSearchParams()

   const { data: conversationsData, isLoading, error } = useConversationCoursesUsers()
   const [courseList, setCourseList] = useState<string[]>([])
   const [userList, setUserList] = useState<string[]>([])

   if (error) {
      toast.error(error.message)
   }

   // initialize form with default values
   useEffect(() => {
      if (conversationsData) {
         setCourseList(conversationsData.courseList)
         setUserList(conversationsData.userList)
      }
   }, [conversationsData])

   const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),
      defaultValues: {
         username: usernameParam,
         courseName: courseNameParam,
      },
   })

   const onReset = () => {
      form.reset({ username: '', courseName: '' })
      setCourseList(conversationsData?.courseList ?? [])
      setUserList(conversationsData?.userList ?? [])
      navigate(location.pathname, { replace: true })
   }

   const onSubmit = (values: z.infer<typeof formSchema>) => {
      setSearchParams({ search: 'true', username: values.username, courseName: values.courseName })
   }

   const formHasValues = form.getValues('username') || form.getValues('courseName')

   return (
      <Form {...form}>
         <form onSubmit={form.handleSubmit(onSubmit)} className="mt-8 flex">
            <div className="flex flex-col w-full sm:w-3/4 sm:flex-row justify-between mx-auto items-stretch gap-3 ">
               <FormField
                  control={form.control}
                  name="username"
                  render={({ field }) => (
                     <FormItem className="w-full sm:w-[45%] space-y-0 ">
                        <FormLabel htmlFor="username"></FormLabel>
                        <FormControl className="h-11">
                           <Popover>
                              <PopoverTrigger
                                 disabled={isLoading}
                                 className={cn(buttonVariants({ variant: 'outline', size: 'lg', className: 'w-full' }))}
                              >
                                 {!field.value && <PlusCircle className="size-5 text-primary pr-1" />}
                                 {field.value ? <span className="truncate w-full">{field.value}</span> : 'Select User'}
                              </PopoverTrigger>
                              <PopoverContent align="start">
                                 <Command>
                                    <CommandInput placeholder="Select User" />
                                    <CommandList>
                                       <CommandEmpty>No results found.</CommandEmpty>
                                       <CommandGroup heading="Users">
                                          {userList?.map((username) => (
                                             <CommandItem
                                                onSelect={() => {
                                                   setSearchParams({ username, courseName: courseNameParam })
                                                   setCourseList(conversationsData?.filterUsers(username) ?? [])
                                                   form.setValue('username', username)
                                                }}
                                                key={username}
                                                value={username}
                                             >
                                                <div
                                                   className={cn(
                                                      'mr-2 flex size-4 items-center justify-center rounded-sm border border-primary',
                                                      username === usernameParam
                                                         ? 'bg-primary text-primary-foreground'
                                                         : 'opacity-50 [&_svg]:invisible',
                                                   )}
                                                >
                                                   <CheckIcon className={cn('size-4')} />
                                                </div>
                                                {username}
                                             </CommandItem>
                                          ))}
                                       </CommandGroup>
                                       <CommandSeparator />
                                    </CommandList>
                                 </Command>
                              </PopoverContent>
                           </Popover>
                        </FormControl>
                        <FormMessage />
                     </FormItem>
                  )}
               />
               <FormField
                  control={form.control}
                  name="courseName"
                  render={({ field }) => (
                     <FormItem className="w-full sm:w-[45%]  space-y-0 ">
                        <FormLabel htmlFor="courseName"></FormLabel>
                        <FormControl className="h-11">
                           <Popover>
                              <PopoverTrigger
                                 disabled={isLoading}
                                 className={cn(buttonVariants({ variant: 'outline', size: 'lg', className: 'w-full' }))}
                              >
                                 {!field.value && <PlusCircle className="pr-1 size-5 text-primary" />}
                                 {field.value ? field.value : 'Select Course'}
                              </PopoverTrigger>
                              <PopoverContent align="start">
                                 <Command>
                                    <CommandInput placeholder="Select Course" />
                                    <CommandList>
                                       <CommandEmpty>No results found.</CommandEmpty>
                                       <CommandGroup heading="Courses">
                                          {courseList.map((course) => (
                                             <CommandItem
                                                onSelect={() => {
                                                   setSearchParams({ username: usernameParam, courseName: course })
                                                   setUserList(conversationsData?.filterCourses(course) ?? [])
                                                   form.setValue('courseName', course)
                                                }}
                                                key={course}
                                                value={course}
                                             >
                                                <div
                                                   className={cn(
                                                      'mr-2 flex size-4 items-center justify-center rounded-sm border border-primary',
                                                      course === field.value
                                                         ? 'bg-primary text-primary-foreground'
                                                         : 'opacity-50 [&_svg]:invisible',
                                                   )}
                                                >
                                                   <CheckIcon className={cn('size-4')} />
                                                </div>
                                                {course}
                                             </CommandItem>
                                          ))}
                                       </CommandGroup>
                                       <CommandSeparator />
                                    </CommandList>
                                 </Command>
                              </PopoverContent>
                           </Popover>
                        </FormControl>
                        <FormMessage />
                     </FormItem>
                  )}
               />
               <div className="flex flex-row gap-3 h-11">
                  <Button disabled={isLoading} type="submit" size="icon" className={cn('h-full w-full flex-grow flex')}>
                     {isLoading ? (
                        <LoaderCircle className="size-5 sm:w-20 animate-spin" />
                     ) : (
                        <Search className="size-5 sm:w-20" />
                     )}
                  </Button>
                  <Button
                     type="reset"
                     size="icon"
                     onClick={onReset}
                     className={cn(`h-full w-full flex-grow ${formHasValues ? 'flex' : 'hidden'}`)}
                  >
                     <X className="size-5 sm:w-20" />
                  </Button>
               </div>
            </div>
         </form>
      </Form>
   )
}
