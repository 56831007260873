// External libraries
import { useMutation, UseMutationResult } from '@tanstack/react-query'

// Global utilities and constants
import { queryClient } from '@/App'
import { toast } from 'sonner'
import { documentKeys } from '@/hooks/documents/documentsQueryKeys'

// Services and API calls
import { addDocumentToDB } from '@/api'

// Hooks and Jotai Atoms
import { userAtom } from '@/store/store'
import { useAtomValue } from 'jotai'

// Types
import { CourseDocuments, Document, BaseDocument } from '@/types/Document'

/**
 * Custom hook for managing document uploads with optimistic updates and cache management.
 *
 * Features:
 * - Optimistic updates for immediate UI feedback
 * - Multi-level cache management (all docs, course docs, single doc)
 * - Automatic rollback on error
 * - Toast notifications for success/error states
 *
 * Cache Structure:
 * - documents(userId) -> All documents across courses
 * - courseDocuments(userId, courseName) -> Documents for specific course
 * - document(userId, courseName, fileName) -> Individual document
 *
 * @example
 * ```tsx
 * const mutation = useCoursesDocumentsMutation();
 *
 * const handleUpload = (doc: Document) => {
 *    mutation.mutate(doc);
 * };
 * ```
 *
 * @returns {UseMutationResult<boolean, Error, Document, unknown>} Mutation object containing:
 * - mutate: (doc: Document) => void - Initiates document upload
 * - isLoading: boolean - Upload in progress
 * - isSuccess: boolean - Upload completed successfully
 * - isError: boolean - Upload failed
 * - error: Error | null - Error details if failed
 */
const useCoursesDocumentsMutation = (): UseMutationResult<boolean, Error, Document, unknown> => {
   const { userId } = useAtomValue(userAtom)
   return useMutation({
      mutationFn: (document: Document) => addDocumentToDB(document),
      onMutate: async (newDocument) => {
         // Cancel any outgoing refetches
         await queryClient.cancelQueries({
            queryKey: documentKeys.all,
         })

         // Snapshot previous values
         const previousDocs = queryClient.getQueryData<CourseDocuments>(documentKeys.byUser(userId)) || {}
         const previousCourseState = [...(previousDocs[newDocument.courseName] || [])]

         // Create optimistic update
         const optimisticDoc: BaseDocument = {
            fileName: newDocument.fileName,
            createdBy: newDocument.createdBy,
            isIndexed: false,
         }

         // Create new state
         const newDocs = { ...previousDocs }
         const courseDocuments = [...(previousDocs[newDocument.courseName] || []), optimisticDoc]
         newDocs[newDocument.courseName] = courseDocuments

         // Update all cache levels immediately
         queryClient.setQueryData(documentKeys.byUser(userId), newDocs)
         queryClient.setQueryData(documentKeys.byCourse(userId, newDocument.courseName), courseDocuments)
         queryClient.setQueryData(
            documentKeys.byDocument(userId, newDocument.courseName, newDocument.fileName),
            optimisticDoc,
         )

         return { previousDocs, previousCourseState }
      },
      onSuccess: (data, newDocument) => {
         toast.success('Document added successfully!', {
            description: 'Document has been added to the database: ' + newDocument.fileName,
         })

         // Invalidate all affected queries to ensure fresh data
         queryClient.invalidateQueries({
            queryKey: documentKeys.byUser(userId),
         })
         queryClient.invalidateQueries({
            queryKey: documentKeys.byCourse(userId, newDocument.courseName),
         })
         queryClient.invalidateQueries({
            queryKey: documentKeys.byDocument(userId, newDocument.courseName, newDocument.fileName),
         })
      },
      onError: (error, newDocument, context) => {
         // Rollback on error
         if (context) {
            queryClient.setQueryData(documentKeys.byUser(userId), context.previousDocs)
            queryClient.setQueryData(documentKeys.byCourse(userId, newDocument.courseName), context.previousCourseState)
         }

         toast.error("Couldn't add document", {
            description: error instanceof Error ? error.message : 'Please try again',
         })
      },
   })
}

export default useCoursesDocumentsMutation
