import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/store'
import { listAllDocumentsFromDB } from '@/api'
import { documentKeys } from '@/hooks/documents/documentsQueryKeys'
import { queryClient } from '@/App'

export default function useAllCourseDocuments() {
   const { userId } = useAtomValue(userAtom)

   return useQuery({
      queryKey: documentKeys.byUser(userId),
      queryFn: async ({ signal }) => {
         const docs = await listAllDocumentsFromDB(userId, signal)

         // Structure data for granular caching
         Object.entries(docs).forEach(([courseName, documents]) => {
            documents.forEach((doc) => {
               queryClient.setQueryData(documentKeys.byDocument(userId, courseName, doc.fileName), doc)
            })
            queryClient.setQueryData(documentKeys.byCourse(userId, courseName), documents)
         })

         return docs
      },
      refetchInterval: (data) => {
         if (!data) return false
         const hasUnindexedDocs = Object.values(data)
            .flat()
            .some((doc) => !doc.isIndexed)
         return hasUnindexedDocs ? 1000 * 60 * 2 : false
      },
      staleTime: 5000, // Consider data fresh for 5 seconds
      gcTime: 1000 * 60 * 10, // Keep unused data for 10 minutes
      retry: (failureCount, error) => {
         return failureCount < 3 && !error.message.includes('not found')
      }
   })
}
