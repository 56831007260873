import { loginRequest } from '@/auth/authConfig'
import { msalInstance } from '@/index'
import { redirect } from 'react-router-dom'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { getDefaultStore } from 'jotai'
import { userAtom } from '@/store/store'

const store = getDefaultStore()

export async function acquireUserTokenFromMsal() {
   try {
      const account = msalInstance.getActiveAccount()
      if (!account) {
         await msalInstance.loginRedirect({
            scopes: ['User.Read'],
         })
         throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.')
      }

      const token = await msalInstance.acquireTokenSilent({
         account: account,
         scopes: loginRequest.scopes || ['User.Read'],
      })

      return { idToken: token.idToken, username: account.username, name: account.name }
   } catch (error) {
      // console.error('Failed to acquire token:', error)
      throw error
   }
}

/**
 * Clears the cache first, then redirects to the logout page.
 * Note that there is no way to programmatically invalidate the token on the server side.
 * This is a client-side operation only.
 */
export const logout = async () => {
   try {
      await msalInstance.logoutRedirect()
   } catch (error) {
      console.error('Logout failed:', error)
   }
}

export const loginRedirect = async () => {
   try {
      await msalInstance.clearCache()
      await msalInstance.loginRedirect(loginRequest)
   } catch (error) {
      // console.error('Token acquisition failed:', error)
   }
}

/**
 * Evaluates if a user's role meets the required permissions to access a page.
 *
 * This function assesses user access by comparing the user's role index against the required role index for a page.
 * A positive difference (required role index greater than user's role index) indicates sufficient permissions,
 * Conversely, a non-positive difference signifies insufficient permissions, resulting in a redirect to the home page.
 *
 * Note: The role index is a numerical representation of a user's role, with lower values indicating higher permissions.
 *
 * @param {number} userRoleIndex - The index representing the user's current role.
 * @param {number} requiredRoleIndex - The minimum role index required to access the page.
 * @returns {number} The user's role index if the user has sufficient permissions.
 * @throws Redirects to the home page if the user lacks the necessary permissions.
 */
export const authorizationLoader = (userRoleIndex: number, requiredRoleIndex: number) => {
   const permissionDifference = requiredRoleIndex - userRoleIndex
   // console.log('\nprevious route:', window.location.pathname)
   // console.log('Permission difference:', permissionDifference)
   // console.log('User role index:', userRoleIndex)
   // console.log('Required role index:', requiredRoleIndex, '\n')
   if (permissionDifference < 0) {
      throw redirect('/')
   }

   return true
}

/**
 * Attempts to acquire a token for the user using MSAL (Microsoft Authentication Library).
 * This function tries to get the token silently but will redirect the user for login if necessary.
 *
 * @returns {Promise<{userId: string, userName: string, idToken: string} | undefined>}
 * A promise that resolves to an object containing user details and ID token if successful, or undefined if not.
 */
export const acquireToken = async () => {
   try {
      const result = await acquireUserTokenFromMsal()

      if (!result?.idToken || !result?.username || !result?.name) {
         // console.error('Missing values from acquireUserTokenFromMsal')
         throw new Error('Missing values from acquireUserTokenFromMsal')
      }

      return {
         userId: result.username,
         userName: result.name,
         idToken: result.idToken,
      }
   } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
         const account = msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0]
         // console.log('User interaction required to acquire token. Redirecting to login page...')
         try {
            await msalInstance.acquireTokenRedirect({
               account: account,
               scopes: loginRequest.scopes || ['User.Read'],
            })
         } catch (error) {
            await msalInstance.loginRedirect({ scopes: ['User.Read'] })
            // console.error('Token acquisition failed: ', error)
         }
      } else {
         try {
            await msalInstance.loginRedirect({ scopes: ['User.Read'] })
            // console.log('User interaction required to acquire token. Redirecting to login page...')
         } catch (error) {
            // console.error('Failed to login: ', error)
         }
      }
      // console.error('Failed to acquire token: ', error)
   } finally {
      // console.log('Auth token acquired successfully!')
   }
}

/**
 * Refreshes the authentication token and updates the user state.
 *
 * @returns {Promise<string>} The refreshed ID token.
 * @throws Will throw an error if the token acquisition fails.
 */
export const refreshAuthTokenAndUpdateUser = async (): Promise<string> => {
   const data = await acquireToken()
   if (!data) {
      throw new Error('Failed to acquire token')
   }
   const user = store.get(userAtom)
   store.set(userAtom, { ...data, ...user })
   return data.idToken
}
